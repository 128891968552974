import { useEffect, useState } from 'react';
import { InsuranceService, Insurance } from 'uai-graos-sdk';
import { notification } from 'antd';

export const useInsurance = () => {
  const [insurances, setInsurances] = useState<
    Insurance.Insurance[]
  >([]);
  const [isLoading, setIsLoading] =
    useState<boolean>(false);

  useEffect(() => {
    getAllInsurances();
  }, []);

  const getAllInsurances = async () => {
    setIsLoading(true);
    await InsuranceService.getAllInsurances()
      .then((resp) => {
        setIsLoading(false);
        setInsurances(resp);
      })
      .catch((err) => {
        new Error(err);
        setIsLoading(false);
      });
  };

  const updateInsurance = async (
    id: string,
    insurance: Insurance.Insurance
  ) => {
    setIsLoading(true);
    await InsuranceService.updateInsurance(id, insurance)
      .then(() => {
        setIsLoading(false);
        window.location.href = '/insurances-crm';
      })
      .catch((err) => {
        new Error(err);
        setIsLoading(false);
      });
  };

  const updateInsuranceStatus = async (
    id: string,
    insuranceStatus: Insurance.Insurance['insuranceStatus'],
    insurance: Insurance.Insurance
  ) => {
    setIsLoading(true);

    const insuranceUpdatedBody = {
      ...insurance,
      insuranceStatus,
      driverInsurances: [],
    };

    await InsuranceService.updateInsurance(
      id,
      insuranceUpdatedBody
    )
      .then(() => {
        setIsLoading(false);
        getAllInsurances();
        notification.success({
          message: 'Sucesso!',
          description: 'Lead Atualizada!',
        });
      })
      .catch((err) => {
        new Error(err);
        setIsLoading(false);
      });
  };

  return {
    updateInsurance,
    updateInsuranceStatus,
    insurances,
    isLoading,
  };
};
