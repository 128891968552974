import {
  Button,
  Col,
  Input,
  notification,
  Row,
  Select,
  Form,
  Checkbox,
  DatePicker,
  Divider,
  Tabs,
  Card,
  Space,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Moment } from 'moment';
import {
  Bid,
  BidService,
  MarketData,
  MarketDataService,
} from 'uai-graos-sdk';
import CashInput from '../components/CashInput/CashInput';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import CityInput from '../components/CitySearchInput';
import AccountSearchInput from '../components/AccountSearchInput';
import { formatInputNumbers } from '../../core/utils/formatInputNumbers';

type BidFormType = {
  deliveryMonth: Moment;
} & Omit<Bid.Bid, 'deliveryMonth'>;

interface BidFormProps {
  bid?: BidFormType;
  OnUpdate?: (bid: Bid.Bid) => any;
  isLoading?: boolean;
}

export default function BidForm(props: BidFormProps) {
  const [accountId, setAccountId] = useState<string>('');
  const [modality, setModality] = useState('');
  const [showSide, setShowSide] = useState(props.bid?.side);
  const [acceptCounterBid, setAcceptCounBid] = useState<
    boolean | undefined
  >(props.bid?.acceptCounterBid);
  const [marketData, setMarketData] =
    useState<MarketData.MarketData[]>();
  const history = useNavigate();

  const { TabPane } = Tabs;

  const { TextArea } = Input;

  const { Text, Title } = Typography;

  const [form] = Form.useForm();

  const showDates = () => {
    if (modality === 'Futuro') {
      return true;
    } else {
      return false;
    }
  };

  const freights = [
    {
      id: '1',
      type: 'CIF - Por conta do Vendedor',
    },
    {
      id: '2',
      type: 'FOB - Por conta do Comprador',
    },
    {
      id: '3',
      type: 'À combinar - CIF ou FOB',
    },
  ];

  const storageOptions = [
    { id: '1', type: 'Silo Metálico' },
    { id: '2', type: 'Silo Bolsa' },
    { id: '3', type: 'Boca de Colheitadeira' },
    { id: '4', type: 'Armazém' },
    { id: '5', type: 'Outros Armazenamentos' },
  ];

  const showModalities = [
    {
      id: '1',
      type: 'Disponível',
    },
    {
      id: '2',
      type: 'Futuro',
    },
  ];

  const products = [
    { id: '1', type: 'Milho' },
    { id: '2', type: 'Soja' },
    { id: '3', type: 'Sorgo' },
  ];

  const side = [
    { id: '1', type: 'C' },
    { id: '2', type: 'V' },
  ];

  const payments = [
    {
      id: '1',
      type: 'No carregamento',
    },
    {
      id: '2',
      type: 'No recebimento',
    },

    {
      id: '3',
      type: 'A prazo máximo de 30 dias',
    },
    {
      id: '4',
      type: 'À combinar',
    },
  ];
  const registerOffer = async (values: any) => {
    if (props.bid)
      return props.OnUpdate && props.OnUpdate(values);
    function filterBestPrice(bestPrice: string) {
      if (!bestPrice) return;
      const replaceCounterOffer = bestPrice.slice(3);
      return parseFloat(replaceCounterOffer);
    }

    function filterPrice(price: string) {
      if (!price) return;
      const replacePrice = price.slice(3);
      return parseFloat(replacePrice);
    }

    const response = await BidService.createBid(accountId, {
      ...values,
      city: values.city.split(' -')[0],
      bagsTotal: formatInputNumbers(values.quantity),
      bagsMinimumApplication: formatInputNumbers(
        values.bagsMinimumApplication
      ),
      price: filterPrice(values.price)!,
      bestPrice: filterBestPrice(values.bestPrice)!,
      quantity: formatInputNumbers(values.quantity),
    });

    notification.success({
      message: 'Sucesso',
      description: 'Oferta criada com sucesso',
    });
    history('/bids');
    return response;
  };

  useEffect(() => {
    MarketDataService.getLastCotation().then(setMarketData);
  }, []);

  const onCityChange = (value: any, option: any) => {
    form.setFieldValue('state', option.children[2]);
  };

  const onAccountChange = (value: any, option: any) => [
    setAccountId(option.key),
  ];

  return (
    <Form
      autoComplete={'off'}
      layout={'vertical'}
      onFinish={registerOffer}
      initialValues={props.bid}
      form={form}
    >
      <Row gutter={24}>
        {!props.bid ? (
          <Col xs={24} lg={8}>
            <AccountSearchInput
              formProps={{
                label: 'Usuário:',
                name: 'username',
                rules: [
                  {
                    required: true,
                    message:
                      'Este campo não pode estar em branco!',
                  },
                ],
              }}
              onAccountChange={onAccountChange}
            />
          </Col>
        ) : null}

        <Col xs={24} lg={8}>
          <Form.Item
            label='Produto'
            name='product'
            rules={[
              {
                required: true,
                message:
                  'Este campo não pode estar em branco!',
              },
            ]}
          >
            <Select showSearch disabled={!!props.bid}>
              {products.map((product: any) => (
                <Select.Option
                  key={product.id}
                  value={product.type}
                >
                  {product.type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label='Tipo de negociação'
            name='side'
            rules={[
              {
                required: true,
                message:
                  'Este campo não pode estar em branco!',
              },
            ]}
          >
            <Select
              value={showSide}
              onChange={(e) => setShowSide(e)}
              showSearch
              disabled={!!props.bid}
            >
              {side.map((product: any) => (
                <Select.Option
                  key={product.id}
                  value={product.type}
                >
                  {product.type === 'V'
                    ? 'Venda'
                    : 'Compra'}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <CityInput
            onCityChange={onCityChange}
            formProps={{
              label: 'Cidade',
              name: 'city',
              rules: [
                {
                  required: true,
                  message:
                    'Este campo não pode estar em branco!',
                },
              ],
            }}
          />
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item label='Estado' name='state'>
            <Input disabled placeholder={'Ex.: MG'} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item label='CEP' name='cep'>
            <Input placeholder={'Ex.: 37800-500'} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label='Quantidade mínima por negociação (saca)'
            name='bagsMinimumApplication'
            initialValue={'700'}
            rules={[
              {
                required: true,
                message:
                  'Este campo não pode estar em branco!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <CashInput
            name='price'
            required={true}
            label='Preço da oferta (saca)'
          />
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label='Forma de pagamento'
            name='payment'
            initialValue={'No carregamento'}
            rules={[
              {
                required: true,
                message:
                  'Este campo não pode estar em branco!',
              },
            ]}
          >
            <Select showSearch>
              {payments.map((payments: any) => (
                <Select.Option
                  key={payments.id}
                  value={payments.type}
                >
                  {payments.type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label='Forma de Entrega'
            name='freight'
            initialValue={'FOB - Por conta do Comprador'}
            rules={[
              {
                required: true,
                message:
                  'Este campo não pode estar em branco!',
              },
            ]}
          >
            <Select showSearch>
              {freights.map((freights: any) => (
                <Select.Option
                  key={freights.id}
                  value={freights.type}
                >
                  {freights.type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label='Quantidade'
            name='quantity'
            rules={[
              {
                required: true,
                message:
                  'Este campo não pode estar em branco!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        {!props.bid && (
          <>
            <Col xs={24} lg={8}>
              <Form.Item
                label='Modalidade'
                name='modality'
                initialValue='Disponível'
                rules={[
                  {
                    required: true,
                    message:
                      'Este campo não pode estar em branco!',
                  },
                ]}
              >
                <Select
                  showSearch
                  value={modality}
                  onChange={(e) => setModality(e)}
                >
                  {showModalities.map((modality: any) => (
                    <Select.Option
                      key={modality.id}
                      value={modality.type}
                    >
                      {modality.type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label='Data de entrega (estimada)'
                name='deliveryMonth'
                rules={[
                  {
                    required: showDates(),
                    message:
                      'Por favor selecione a data de entrega!',
                  },
                ]}
              >
                <DatePicker
                  disabled={!showDates()}
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                />
              </Form.Item>
            </Col>
          </>
        )}
        {showSide === 'V' ? (
          <>
            <Col xs={24} lg={8}>
              <Form.Item
                label='Armazenamento'
                name='storage'
                rules={[
                  {
                    required: true,
                    message:
                      'Este campo não pode estar em branco!',
                  },
                ]}
              >
                <Select showSearch>
                  {storageOptions.map((storage: any) => (
                    <Select.Option
                      key={storage.id}
                      value={storage.type}
                    >
                      {storage.type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item label='Observação' name='obs'>
                <TextArea
                  showCount
                  maxLength={350}
                  style={{ height: 120, marginBottom: 24 }}
                  placeholder='Insira uma observação'
                />
              </Form.Item>
            </Col>
          </>
        ) : null}
      </Row>
      <Tabs defaultActiveKey={'personal'}>
        <TabPane
          key={'personal'}
          tab={'Informações de Contraproposta'}
        >
          <Row gutter={24}>
            <Col xs={24} lg={8}>
              <Form.Item
                name='acceptCounterBid'
                valuePropName='checked'
              >
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) =>
                    setAcceptCounBid(e.target.checked)
                  }
                >
                  Aceita contraproposta
                </Checkbox>
              </Form.Item>
            </Col>

            {acceptCounterBid && (
              <Col xs={24} lg={8}>
                <Form.Item name='bestPrice'>
                  <CashInput
                    name='bestPrice'
                    label='Preço min/max da Contraproposta (saca)'
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </TabPane>
      </Tabs>
      <Divider />
      <Title level={5}>Tabela de preços:</Title>
      <Row>
        <Space direction='horizontal' size={16}>
          {marketData?.map(
            (item) =>
              item?.marketDataId?.symbol !==
                'Dólar Spot Comercial' && (
                <Col
                  span={8}
                  key={item.marketDataId?.symbol}
                >
                  <Card
                    title={`${item.marketDataId?.symbol}`}
                    style={{ width: 300 }}
                  >
                    <Space direction='vertical'>
                      <Text type='success'>
                        Preço mínimo: R${' '}
                        {(
                          item.lastPrice! -
                          item.lastPrice! * 0.3
                        ).toFixed(2)}
                      </Text>
                      <Text type='warning'>
                        Preço médio: R${' '}
                        {item.lastPrice!.toFixed(2)}
                      </Text>
                      <Text type='danger'>
                        Preço máximo: R${' '}
                        {(
                          item.lastPrice! * 0.3 +
                          item.lastPrice!
                        ).toFixed(2)}
                      </Text>
                    </Space>
                  </Card>
                </Col>
              )
          )}
        </Space>
      </Row>
      <Row justify='end'>
        <Col>
          <Form.Item>
            <Button
              loading={props.isLoading}
              style={{ marginTop: 16 }}
              type='primary'
              size='large'
              htmlType='submit'
            >
              {props.bid ? 'Salvar' : 'Criar Oferta'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
