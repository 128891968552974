import {
  Button,
  Col,
  Row,
  Skeleton,
  Space,
  Typography,
  Progress,
  Descriptions,
  Divider,
  Tooltip,
  Table,
  Tag,
} from 'antd';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import usePosts from '../../core/hooks/usePosts';
import usePageTitle from '../../core/hooks/usePageTitle';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import Profile from './../../assets/Profile.png';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import moment from 'moment';
import { EyeOutlined } from '@ant-design/icons';

export default function UserDetailsView() {
  usePageTitle('Detalhes do usuário');

  const params = useParams<{ id: string | undefined }>();

  const { lg } = useBreakpoint();

  const { fetchUserPosts, posts } = usePosts();

  useBreadcrumb(`Usuários/${posts?.name || 'Detalhes'}`);

  useEffect(() => {
    fetchUserPosts(params.id!);
  }, [fetchUserPosts, params.id]);

  if (!posts) return <Skeleton />;

  return (
    <Row gutter={48}>
      <Col xs={24} lg={20}>
        <Space
          style={{ width: '100%' }}
          direction='vertical'
          align={lg ? 'start' : 'center'}
        >
          <Typography.Title level={2}>
            {posts?.name}
          </Typography.Title>
          <Typography.Paragraph>
            {posts?.email}
          </Typography.Paragraph>
        </Space>
        <Space>
          <Link to={`/users/edit/${params.id}`}>
            <Button type='primary'>Editar Perfil</Button>
          </Link>

          <Button type='primary'>Desabilitar</Button>
        </Space>
      </Col>
      <Divider />
      <Col xs={24} lg={12}>
        <Space
          direction='vertical'
          style={{ width: '100%' }}
        >
          <div>
            <Typography.Text>Milho</Typography.Text>
            <Progress
              percent={posts?.numberBidsCorn}
              format={(percent) => `${percent} Ofertas`}
            />
          </div>
        </Space>
        <Space
          direction='vertical'
          style={{ width: '100%' }}
        >
          <div>
            <Typography.Text>Soja</Typography.Text>
            <Progress
              percent={posts?.numberBidsSoy}
              format={(percent) => `${percent} Ofertas`}
            />
          </div>
        </Space>
        <Space
          direction='vertical'
          style={{ width: '100%' }}
        >
          <div>
            <Typography.Text>Sorgo</Typography.Text>
            <Progress
              percent={posts?.numberBidsSorghum}
              format={(percent) => `${percent} Ofertas`}
            />
          </div>
        </Space>
      </Col>
      <Col xs={24} lg={12}>
        <Descriptions column={1} bordered size='small'>
          <Descriptions.Item label={'Cidade'}>
            {posts?.city}
          </Descriptions.Item>
          <Descriptions.Item label={'Estado'}>
            {posts?.state}
          </Descriptions.Item>
          <Descriptions.Item label={'CPF/CNPJ'}>
            {posts?.document}
          </Descriptions.Item>
          <Descriptions.Item label={'Telefone'}>
            {posts?.phoneNumber
              ? posts?.phoneNumber
              : 'Telefone não cadastrado'}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Divider />
      <Col xs={24}>
        <Table
          dataSource={posts?.bids}
          rowKey={'bidId'}
          columns={[
            {
              dataIndex: 'bidId',
              title: 'Id da oferta',
              responsive: ['sm'],
            },
            {
              dataIndex: 'symbol',
              title: 'Produto',
              responsive: ['sm'],
              render(title: string) {
                return <p>{title.toUpperCase()}</p>;
              },
            },
            {
              dataIndex: 'createdAt',
              title: 'Data de criação',
              align: 'center',
              responsive: ['sm'],
              render: (item) =>
                moment(item).format(
                  'DD/MM/YYYY \\à\\s hh:mm'
                ),
            },
            {
              dataIndex: 'side',
              title: 'Tipo de oferta',
              align: 'center',
              responsive: ['sm'],
              render: (side: any) => (
                <Tag
                  color={side === 'C' ? 'green' : 'red'}
                  key={side}
                >
                  {side === 'C' ? 'COMPRA' : 'VENDA'}
                </Tag>
              ),
            },
            {
              dataIndex: 'bidId',
              title: 'Ações',
              align: 'center',
              responsive: ['sm'],
              render: (id: any) => (
                <Tooltip title='Visualizar Usuário'>
                  <Link to={`/bids/edit/${id}`}>
                    <EyeOutlined />
                  </Link>
                </Tooltip>
              ),
            },
          ]}
        />
      </Col>
    </Row>
  );
}
