/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Tabs,
  Button,
  notification,
  DatePicker,
  DatePickerProps,
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cepValidate from 'cep-promise';
import { User } from '../../types/User';
import dayjs from 'dayjs';
import { Account, AccountService } from 'uai-graos-sdk';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { formatDocument } from '../../core/utils/formatDocument';

const { TabPane } = Tabs;

interface UserFormProps {
  user?: User.UserDetailed;
  OnUpdate?: (user: User.UserDetailed) => any;
}

export default function UserForm(props: UserFormProps) {
  const history = useNavigate();

  const [form] = Form.useForm();
  const [birthDate, setBirthDate] = useState('');
  const [showDeadLineApproval, setShowDeadLineApproval] =
    useState(false);

  const [activeTab, setActiveTab] = useState<
    'localizationData' | 'personalData'
  >('localizationData');

  const lineBusiness = [
    {
      value: 'placeholder',
      label: 'Selecione o ramo de atuação',
    },
    { value: 'Indústria', label: 'Indústria' },
    { value: 'Armazém', label: 'Armazém' },
    { value: 'Produtor', label: 'Produtor' },
    { value: 'Corretor', label: 'Corretor' },
  ];

  async function registerUser(item: any) {
    if (props.user) {
      return props.OnUpdate && props.OnUpdate(item);
    }

    if (item.confirmPass === item.pass) {
      const account: Account.AccountInput = {
        ...item,
        origin: 'BACK_OFFICE',
      };

      console.log(account);
      await AccountService.createAccount(account).then(
        () => {
          notification.success({
            message: 'Sucesso',
            description: 'Conta criada com sucesso',
          });
          history('/users');
        }
      );
    } else {
      Error('As senhas não coincidem');
    }
  }

  const taxesItems = [
    { key: 1, value: 'Na nota' },
    { key: 2, value: 'Na folha' },
  ];
  const deliveryConditionsItems = [
    {
      key: 1,
      value: 'FOB',
      label: 'FOB - Por conta do comprador',
    },
    {
      key: 2,
      value: 'CIF',
      label: 'CIF - Por conta do vendedor',
    },
    {
      key: 3,
      value: 'A combinar',
      label: 'A combinar - CIF ou FOB',
    },
  ];

  const deadLineApprovalItems = [
    { key: 1, value: '7 dias' },
    { key: 2, value: '15 dias' },
    { key: 3, value: '30 dias' },
    { key: 4, value: 'À combinar' },
  ];

  const paymentTerms = [
    { key: 1, value: 'À vista' },
    { key: 2, value: 'À prazo' },
    { key: 3, value: 'À combinar' },
  ];
  const activityItems = [
    { key: 1, value: 'Não Especificado' },
    { key: 2, value: 'Produtor de Grãos' },
    { key: 3, value: 'Granjeiro de Suínos' },
    { key: 4, value: 'Granjeiro de Aves' },
    { key: 5, value: 'Confinamento de Gado' },
  ];

  const productItems = [
    { key: 1, value: 'Milho' },
    { key: 2, value: 'Soja' },
    { key: 3, value: 'Sorgo' },
  ];

  const onPaymentChange = (value: any) => {
    if (!value || value === 'Á vista') {
      setShowDeadLineApproval(false);
      return;
    }
    setShowDeadLineApproval(true);
  };
  const onChangeDate: DatePickerProps['onChange'] = (
    date,
    dateString: any
  ) => {
    const formattedDate: any =
      dayjs(dateString).format('DD/MM/YYYY');
    if (formattedDate === 'Invalid Date') {
      setBirthDate('');
    } else {
      setBirthDate(formattedDate);
    }
  };
  const checkCep = async (e: any) => {
    const cep = e.target.value.replace(/\D/g, '');
    await cepValidate(cep)
      .then((e) =>
        form.setFieldsValue({
          state: e.state,
          city: e.city,
          street: e.street,
          district: e.neighborhood,
        })
      )
      .catch(() => {
        notification.error({
          message: 'Erro!',
          description: 'O CEP não foi encontrado!',
        });
      });
  };
  function handleChangeMask(event: any) {
    const { value } = event.target;
    return formatDocument(value);
  }

  return (
    <Form
      form={form}
      initialValues={props.user}
      autoComplete={'off'}
      layout={'vertical'}
      onFinish={registerUser}
    >
      <Tabs>
        <TabPane
          key={'mandatoryData'}
          tab={'Dados Iniciais'}
        >
          <Row gutter={24}>
            <Col xs={24} lg={8}>
              <Form.Item
                label={'Nome'}
                name={'username'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O nome não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input placeholder={'E.g.: João Silva'} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                name='cpfCnpj'
                label='CPF/CNPJ'
                getValueFromEvent={handleChangeMask}
              >
                <Input
                  onChange={handleChangeMask}
                  placeholder={'086.091.486-02'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item label={'RG'} name={'rg'}>
                <Input placeholder={'MG-28.554.262'} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label={'Data de nascimento'}
                name={'birthDate'}
              >
                <DatePicker
                  placeholder={'08/06/1998'}
                  onChange={onChangeDate}
                  format='DD/MM/YYYY'
                  style={{ width: '100%' }}
                />
                {/* <Input /> */}
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item label={'CEP'} name={'cep'}>
                <Input
                  onBlur={checkCep}
                  placeholder={'E.g.: 38790000'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label={'Perfil'}
                name={'typePerson'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    type: 'enum',
                    enum: [
                      'BUYER',
                      'SELLER',
                      'BROKER',
                      'STORAGE',
                    ],
                    message: `O Perfil precisar ser corretor, armazém ou vendedor`,
                  },
                ]}
              >
                <Select placeholder={'Selecione um perfil'}>
                  <Select.Option value={'BUYER'}>
                    Comprador
                  </Select.Option>
                  <Select.Option value={'SELLER'}>
                    Vendedor
                  </Select.Option>
                  <Select.Option value={'BROKER'}>
                    Corretor
                  </Select.Option>
                  <Select.Option value={'STORAGE'}>
                    Armazém
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label={'Telefone'}
                name={'phoneNumber'}
                rules={[
                  {
                    required: true,
                    message:
                      'O campo de telefone é obrigatório',
                  },
                ]}
              >
                {props.user ? (
                  <Input placeholder={'+5527999990000'} />
                ) : (
                  <Input placeholder={'(27) 99999-0000'} />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label={'E-Mail'}
                name={'email'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O e-mail não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input
                  type='email'
                  placeholder={'E.g.: contato@joao.silva'}
                />
              </Form.Item>
            </Col>
            {!props.user ? (
              <>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Senha'}
                    name={'pass'}
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                      {
                        min: 8,
                        message: `A senha tem que ter no mínimo 8 dígitos`,
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder='Ex.: ********'
                      iconRender={(visible) =>
                        visible ? (
                          <EyeTwoTone />
                        ) : (
                          <EyeInvisibleOutlined />
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Confirmar Senha'}
                    name={'confirmPass'}
                    dependencies={['pass']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                      {
                        min: 8,
                        message: `A senha tem que ter no mínimo 8 dígitos`,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue('pass') === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              'As senhas não coincidem!'
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder='Ex.: ********'
                      iconRender={(visible) =>
                        visible ? (
                          <EyeTwoTone />
                        ) : (
                          <EyeInvisibleOutlined />
                        )
                      }
                    />
                  </Form.Item>
                </Col>
              </>
            ) : null}
          </Row>
        </TabPane>
      </Tabs>
      <Row gutter={24} align={'middle'}>
        <Col sm={24}>
          <Tabs
            defaultActiveKey={'personalData'}
            activeKey={activeTab}
            onChange={(tab) =>
              setActiveTab(
                tab as 'personalData' | 'localizationData'
              )
            }
          >
            <TabPane
              key={'localizationData'}
              tab={'Dados de Localização'}
            >
              <Row gutter={24}>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Estado'}
                    name={'state'}
                  >
                    <Input placeholder={'Ex.: MG'} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item label={'Cidade'} name={'city'}>
                    <Input
                      placeholder={'Ex.: Patos de Minas'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Bairro'}
                    name={'district'}
                  >
                    <Input
                      placeholder={'E.g.: Alto Caiçaras'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Endereço'}
                    name={'street'}
                  >
                    <Input
                      placeholder={
                        'E.g.: Rua Paulista, Av. Brasil'
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Nº'}
                    name={'addressNumber'}
                  >
                    <Input placeholder={'E.g.: 568'} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Complemento'}
                    name={'complement'}
                  >
                    <Input
                      placeholder={'E.g.: casa, apto ..'}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
            <TabPane
              key={'personalData'}
              tab={'Dados pessoais'}
            >
              <Row gutter={24}>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Inscrição Estadual'}
                    name={'stateRegistration'}
                  >
                    <Input
                      placeholder={'Ex.: 003.454.434.00-98'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Produto de Interesse'}
                    name={'interestProduct'}
                  >
                    <Select
                      placeholder='Selecione os produtos:'
                      // mode="multiple"
                    >
                      {productItems.map((item) => (
                        <Select.Option
                          key={item.key}
                          value={item.value}
                        >
                          {item.value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Atividade'}
                    name={'activity'}
                    initialValue='Não Especificado'
                  >
                    <Select placeholder='Selecione uma Atividade'>
                      {activityItems.map((item) => (
                        <Select.Option key={item.key}>
                          {item.value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Imposto'}
                    name={'tax'}
                    initialValue='Na nota'
                  >
                    <Select
                      placeholder={'Selecione o imposto'}
                    >
                      {taxesItems.map((item) => (
                        <Select.Option
                          key={item.key}
                          value={item.value}
                        >
                          {item.value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Condições de Entrega'}
                    name={'deliveryInformation'}
                  >
                    <Select placeholder='Selecione as condições de entrega'>
                      {deliveryConditionsItems.map(
                        (item) => (
                          <Select.Option
                            key={item.key}
                            value={item.value}
                          >
                            {item.label}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={'Termos de Pagamento'}
                    name={'paymentTerms'}
                    initialValue={'À vista'}
                  >
                    <Select
                      allowClear
                      onChange={onPaymentChange}
                    >
                      {paymentTerms.map((item) => (
                        <Select.Option
                          key={item.key}
                          value={item.value}
                        >
                          {item.value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {showDeadLineApproval && (
                  <Col xs={24} lg={8}>
                    <Form.Item
                      label={'Prazo de aprovação'}
                      name={'deadlineApproval'}
                    >
                      <Select>
                        {deadLineApprovalItems.map(
                          (item) => (
                            <Select.Option
                              key={item.key}
                              value={item.value}
                            >
                              {item.value}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </TabPane>
          </Tabs>
        </Col>
        <Col xs={24}>
          <Row justify={'end'}>
            <Button
              type={'primary'}
              size='large'
              htmlType={'submit'}
            >
              {!props.user ? 'Cadastrar usuário' : 'Salvar'}
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
