/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import Api from '../services/Api.js';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Profile from './../../assets/Profile.png';
import {
  Link,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Account, AccountService } from 'uai-graos-sdk';
import { format as formatPhoneNumber } from 'telefone';
const { RangePicker } = DatePicker;

export default function UserList() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [accounts, setAccounts] =
    useState<Account.Account[]>();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] =
    useState<Account.Query>({
      page: Number(searchParams.get('page')) || 0,
      size: 10,
      sort: ['updatedAt', 'DESC'],
      city: '',
      email: '',
      state: '',
      username: '',
    });
  const [switchLoading, setSwitchLoading] =
    useState<boolean>(false);

  useEffect(() => {
    responseApi(pagination);
    searchParams.set('page', `${pagination.page}`);
    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }, []);

  const brazilianStates = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  const responseApi = async (query: Account.Query) => {
    setLoading(true);
    AccountService.getAllAccounts(query)
      .then((res) => {
        const { content, totalElements } = res;

        setAccounts(content);

        setPagination((prevPagination) => ({
          ...prevPagination,
          total: totalElements,
          showTotal: (total: number, range: any) =>
            `${range[0]}-${range[1]} de ${total} registros`,
          page: query.page,
        }));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handlePaginationChange = (
    page: any,
    pageSize: any
  ) => {
    searchParams.set('page', `${page - 1}`);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page - 1,
      pageSize: pageSize,
    }));
    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true } // Equivalente ao history.replace()
    );

    responseApi({
      ...pagination,
      page: page - 1,
      size: pageSize,
    });
  };

  const handleSwitchChange = async (
    record: any,
    checked: any
  ) => {
    const { id } = record;
    setSwitchLoading(true);
    if (!checked === false) {
      await AccountService.forceActivateAccount(id).then(
        () => {
          setSwitchLoading(false);
          notification.success({
            style: { zIndex: 100 },
            message: 'Sucesso!',
            description: 'O usuário agora está ativo!',
          });
          responseApi({
            ...pagination,
            page: pagination.page,
            size: pagination.size,
          });
        }
      );
    } else if (!checked === true) {
      await AccountService.deactivateAccount(id).then(
        () => {
          setSwitchLoading(false);
          notification.success({
            style: { zIndex: 100 },
            message: 'Sucesso!',
            description: 'O usuário agora está inativo!',
          });
          responseApi({
            ...pagination,
            page: pagination.page,
            size: pagination.size,
          });
        }
      );
    }
  };
  const [form] = Form.useForm();
  const handleDateFilter = async (
    dates: any,
    dateStrings: [string, string]
  ) => {
    const startDate = new Date(dateStrings[0]);
    const endDate = new Date(dateStrings[1]);
    const formattedStartDate = `${startDate.getFullYear()}-${(
      startDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${startDate
      .getDate()
      .toString()
      .padStart(2, '0')}`;
    const formattedEndDate = `${endDate.getFullYear()}-${(
      endDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${endDate
      .getDate()
      .toString()
      .padStart(2, '0')}`;

    await Api.getAccounts(
      0,
      10,
      formattedStartDate,
      formattedEndDate
    )
      .then((resp) => {
        setAccounts(resp.data.content);
      })
      .catch((e) => {
        notification.error({
          message: 'Erro!',
          description: `Erro código: ${e.response.error} por favor entre em contato com o administrador!`,
        });
      });
  };

  const deleteAccount = async (record: any) => {
    if (record.virtual) {
      await AccountService.restoreAccount(record.id).then(
        () => {
          notification.success({
            message: 'Sucesso!',
            description: 'O usuário foi restaurado',
          });
          responseApi({
            ...pagination,
            page: pagination.page,
            size: pagination.size,
          });
        }
      );
    } else {
      await AccountService.deleteAccount(record.id).then(
        (resp) => {
          if (resp.status === 204) {
            notification.success({
              message: 'Sucesso!',
              description: 'O usuário foi excluido',
            });
            responseApi({
              ...pagination,
              page: pagination.page,
              size: pagination.size,
            });
          }
        }
      );
    }
  };

  const columns = [
    {
      key: '1',
      title: 'Pessoa',
      dataIndex: 'username',
      width: 200,
      render: (username: string) => {
        return (
          <Tooltip title={username}>
            <p
              style={{
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {username}
            </p>
          </Tooltip>
        );
      },
    },
    {
      key: '2',
      title: 'E-Mail',
      dataIndex: 'email',
      width: 300,
      render: (email: any) => (
        <Tooltip title={email}>
          <p
            style={{
              maxWidth: '200px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {email}
          </p>
        </Tooltip>
      ),
    },
    {
      key: '3',
      title: 'Telefone',
      dataIndex: 'phoneNumber',
      render: (phoneNumber: string) =>
        formatPhoneNumber(phoneNumber) || phoneNumber,
    },
    {
      key: '4',
      title: 'Perfil',
      dataIndex: 'typePerson',
      width: 100,
      render: (_: any, { typePerson }: any) => (
        <Tag
          style={{ width: 90, textAlign: 'center' }}
          color={
            typePerson === 'BUYER'
              ? 'green'
              : typePerson === 'SELLER'
              ? 'red'
              : typePerson === 'BROKER'
              ? 'purple'
              : 'gold'
          }
          key={typePerson}
        >
          {typePerson === 'BUYER'
            ? 'COMPRADOR'
            : typePerson === 'SELLER'
            ? 'VENDEDOR'
            : typePerson === 'BROKER'
            ? 'CORRETOR'
            : 'ARMAZÉM'}
        </Tag>
      ),
    },
    {
      key: '5',
      title: 'Cidade',
      dataIndex: 'city',
    },
    {
      key: '6',
      title: 'Estado',
      dataIndex: 'state',
    },
    {
      key: '7',
      title: 'Data de criação',
      dataIndex: 'createdAt',
      render: (date: number) => {
        return (
          <span>
            {moment(date).utc().format('DD/MM/YYYY')}
          </span>
        );
      },
      filterIcon: (filtered: any) => (
        <FilterOutlined
          style={{
            color: filtered ? '#1890ff' : undefined,
          }}
        />
      ),
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <RangePicker
            onChange={handleDateFilter}
            format='DD/MM/YYYY'
          />
        </div>
      ),
    },
    {
      key: '8',
      title: 'Validado?',
      dataIndex: 'status',
      width: 100,
      render: (status: boolean, record: any) => (
        <Switch
          loading={switchLoading}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={status}
          onChange={(checked: any) =>
            handleSwitchChange(record, checked)
          }
        />
      ),
    },
    {
      key: '9',
      title: 'Excluído?',
      dataIndex: 'virtual',
      width: 100,
      render: (virtual: boolean) => (
        <p>{virtual ? 'Sim' : 'Não'}</p>
      ),
    },
    {
      key: '10',
      title: 'Ações',
      dataIndex: 'id',
      render: (id: any, record: any) => (
        <Space size='middle'>
          <Tooltip title='Editar Usuário'>
            <Link to={`users/edit/${id}`}>
              <EditOutlined />
            </Link>
          </Tooltip>
          <Tooltip title='Visualizar Usuário'>
            <Link to={`users/${id}`}>
              <EyeOutlined />
            </Link>
          </Tooltip>
          <Tooltip
            title={`${
              record.virtual ? 'Restaurar' : 'Excliur'
            } Usuário`}
          >
            <PoweroffOutlined
              onClick={() => {
                deleteAccount(record);
              }}
              style={{
                color: `${
                  record.virtual ? '#ff7875' : '#4096ff'
                }`,
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  const onFinishFilter = (values: any) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 0,
      username: values.username,
      email: values.email,
      city: values.city,
      state: values.state,
    }));
    responseApi({
      ...pagination,
      page: 0,
      username: values.username,
      email: values.email,
      city: values.city,
      state: values.state,
    });
  };

  const onResetFilter = () => {
    setPagination({
      page: 0,
      size: 10,
    });
    responseApi({
      page: 0,
      size: 10,
    });
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={onFinishFilter}
        onReset={onResetFilter}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name='username' label='Pessoa'>
              <Input placeholder='Nome da Pessoa:' />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='email' label='E-Mail'>
              <Input placeholder='E-Mail:' />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='city' label='Cidade'>
              <Input placeholder='Cidade:' />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='state' label='Estado'>
              <Select
                placeholder='Selecione um Estado:'
                options={brazilianStates}
                allowClear
              />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item name='side' label='Tipo'>
              <Select
                defaultValue={'ALL'}
                options={sideList}
                allowClear
              />
            </Form.Item>
          </Col> */}
        </Row>
        <Row justify={'end'}>
          <Col>
            <Button
              type='primary'
              danger
              htmlType='reset'
              style={{
                marginRight: 10,
              }}
            >
              Limpar Filtros
            </Button>
            <Button type='primary' htmlType='submit'>
              Pesquisar
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={accounts}
        pagination={{
          ...pagination,
          current: pagination.page! + 1,
          onChange: handlePaginationChange,
          size: 'default',
        }}
        loading={loading}
      />
    </div>
  );
}
