import { Avatar, Space, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { Company } from 'uai-graos-sdk';
import { UserAddOutlined } from "@ant-design/icons";
import { useCompany } from '../../core/hooks/useCompany';
import { getAccountType, getColorType } from '../../core/utils/getAccountType';
import { formatPhoneTable } from '../../core/utils/formatPhoneNumber';

export default function PreRegisterList() {

    const { accounts, pagination, handlePaginationChange, isLoading, createAccountByCompany } = useCompany()

    const columns = [
        {
            key: '1',
            title: 'Pessoa',
            dataIndex: 'name',
            width: 200,
            render: (username: string) => {
                return (
                    <Tooltip title={username}>
                        <p
                            style={{
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {username}
                        </p>
                    </Tooltip>
                );
            },
        },
        {
            key: '2',
            title: 'Telefone',
            dataIndex: 'phone',
            width: 300,
            render: (phone: any) => (
                <Tooltip title={formatPhoneTable(phone)}>
                    <p
                        style={{
                            maxWidth: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {formatPhoneTable(phone)}
                    </p>
                </Tooltip>
            ),
        },
        {
            key: '3',
            title: 'Perfil (Tipo)',
            dataIndex: 'type',
            width: 100,
            render: (_: any, { type }: any) => (
                <Tag
                    style={{ width: 90, textAlign: 'center' }}
                    color={getColorType(type)}
                    key={type}
                >
                    {getAccountType(type)}
                </Tag>
            ),
        },
        {
            key: '4',
            title: 'Cidade',
            dataIndex: 'city',
        },
        {
            key: '5',
            title: 'Estado',
            dataIndex: 'state',
            render: (state: string) => {
                if (!state) {
                    return 'Não Informado'
                }
                return state
            }
        },
        {
            key: '6',
            title: 'Data de criação',
            dataIndex: 'createdAt',
            render: (date: number) => {
                return (
                    <span>
                        {moment(date).utc().format('DD/MM/YYYY')}
                    </span>
                );
            },
        },
        {
            key: '7',
            title: 'Ações',
            dataIndex: 'id',
            render: (id: any, record: Company.Company) => (
                <Space size='middle'>
                    <Tooltip title='Adicionar usuário'>
                        <UserAddOutlined onClick={() => createAccountByCompany(id, record)} />
                    </Tooltip>
                </Space>
            ),
        },
    ];


    return (
        <Table
            columns={columns}
            dataSource={accounts}
            loading={isLoading}
            pagination={{
                ...pagination,
                size: 'default',
                current: pagination.page! + 1,
                onChange: handlePaginationChange,
            }}
        />
    )
} 