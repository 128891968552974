import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from '@syncfusion/ej2-react-kanban';
import '../../index.less';
import { useInsurance } from '../../core/hooks/useInsurance';
import { Skeleton } from 'antd';
import InsuranceKanBanDialog from '../components/InsuranceKanBanDialog';
import InsuranceKanBanCard from '../components/InsuranceKanBanCard';

export default function InsurancesCRM() {
  const {
    isLoading,
    insurances,
    updateInsuranceStatus,
  } = useInsurance();
  if (isLoading) return <Skeleton />;

  return (
    <div style={{ width: '100%' }}>
      <KanbanComponent
        id='kanban'
        keyField='insuranceStatus'
        dataSource={insurances}
        cardSettings={{
          contentField: 'city',
          headerField: 'id',
          template: InsuranceKanBanCard,
        }}
        locale='pt-BR'
        cssClass='kan-ban-container'
        dialogSettings={{
          model: {
            header: 'Visualizar Seguro',
          },
          template: InsuranceKanBanDialog,
        }}
        dataSourceChanged={(e) => {
          updateInsuranceStatus(
            e.changedRecords[0].id,
            e.changedRecords[0].insuranceStatus,
            e.changedRecords[0]
          );
        }}
      >
        <ColumnsDirective>
          <ColumnDirective
            headerText='Novo Seguro'
            keyField='NEW_INSURANCE'
          />
          <ColumnDirective
            headerText='Contato Feito'
            keyField='CONTACT_MADE'
          />
          <ColumnDirective
            headerText='Proposta Enviada'
            keyField='PROPOSAL_SENT'
          />
          <ColumnDirective
            headerText='Proosta Cancelada'
            keyField='PROPOSAL_DECLINED'
          />
          <ColumnDirective
            headerText='Refazer Proposta'
            keyField='REDO_PROPOSAL'
          />
          <ColumnDirective
            headerText='Seguro Concretizado'
            keyField='INSURANCE_DONE'
          />
        </ColumnsDirective>
      </KanbanComponent>
    </div>
  );
}
