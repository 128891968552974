import { User } from '../../types/User';
import Api from '../services/Api';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import useUser from '../../core/hooks/useUser';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import UserForm from '../features/UserForm';
import { notification, Skeleton } from 'antd';
import moment from 'moment';

export default function UserEditView() {
  usePageTitle('Edição do usuário');
  useBreadcrumb('Usuários/Edição');

  const history = useNavigate();
  const { user, fetchUser } = useUser();
  const params = useParams<{ id: any }>();

  useEffect(() => {
    fetchUser(params.id);
  }, [fetchUser, params.id]);

  const transformUserData = useCallback(
    (user: User.UserDetailed) => {
      return {
        ...user,
        birthDate: (user.birthDate && moment(user.birthDate,'DD/MM/YYYY')) || ''
      };
    },
    []
  );
 

  async function handleUserUpdate(user: User.UserDetailed) {
    return await Api.updateAccount(
      params.id,
      user.username,
      user.cpfCnpj,
      user.rg,
      user.birthDate,
      user.typePerson,
      user.email,
      user.phoneNumber,
      user.pass,
      user.stateRegistration,
      user.interestProduct,
      user.activity,
      user.tax,
      user.deliveryInformation,
      user.paymentTerms,
      user.deadlineApproval,
      user.cep,
      user.district,
      user.addressNumber,
      user.street,
      user.complement,
      user.state,
      user.city,
    ).then(() => {
      notification.success({
        message: 'O usuário foi atualizado',
      });
      history('/users');
    });
  }
  if (!user) return <Skeleton />;

  return (
    <>
      <UserForm
        OnUpdate={handleUserUpdate}
        user={transformUserData(user)}
      />
    </>
  );
}
