import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import 'moment/locale/pt-br';

import reportWebVitals from './reportWebVitals';
import { store } from './core/store';
import { registerLicense } from '@syncfusion/ej2-base';
import MuiLayout from './app/layouts/Default/MuiLayout';
import Router from './app/routes';
import './auth/httpConfig';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Login from './app/features/Login/Login';

registerLicense(
  'ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9Sdk1iWX1acX1SRmVe'
);

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

root.render(
  <div style={{ overflow: 'auto' }}>
    <React.StrictMode>
      <ConfigProvider>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route 
                path="/*" 
                element={
                  <MuiLayout>
                    <Router />
                  </MuiLayout>
                } 
              />
            </Routes>
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    </React.StrictMode>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();