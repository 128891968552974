import { Route, Routes } from 'react-router-dom';

import { useEffect } from 'react';

import { notification } from 'antd';
import { CustomError, Service } from 'uai-graos-sdk';

import UserDetailsView from './views/UserDetails.view';
import HomeView from './views/Home.view';
import UserCreateView from './views/UserCreate.view';
import UserEditView from './views/UserEdit.view';
import UserListView from './views/UserList.view';
import BidCreateView from './views/BidCreate.view';
import BuyOffersListView from './views/BidsList.view';

import PaymentSell from './features/PaymentSell';
import CreateMarketData from './views/CreateMarketData';
import NegotiationsList from './views/NegotiationsList';
import BidEditView from './views/BidEdit.view';
import Login from './features/Login/Login';
import NegotiationsCreate from './views/NegotiationsCreate';
import ListMarketData from './views/ListMarketData';
import ContractCreateForm from './features/ContractCreateForm';
import CounterBidCreateForm from './features/CounterBidCreateForm';
import CounterBidList from './features/CounterBidList';
import ConcractUploadForm from './features/ConcractUploadForm';
import ConcractSignForm from './features/ContractSignForm';
import OperationList from './features/OperationList';
import OperationView from './features/OperationView';
import BarterList from './features/BarterList';
import RepresentativeList from './features/RepresentativeList';
import RepresentativeForm from './features/RepresentativeForm';
import BarterQuotationDetails from './views/BarterQuotationDetails.view';
import PreRegisterList from './features/PreRegisterList';
import LeadsCRM from './features/LeadsCRM';
import InsurancesCRM from './features/InsurancesCRM';

export default function Router() {
  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    Service.setBaseUrl(`${baseUrl}/v1`);

    window.onunhandledrejection = ({ reason }) => {
      if (reason instanceof CustomError) {
        if (reason.message) {
          notification.error({
            message: 'Erro!',
            description: reason.message,
          });
          return;
        }
        notification.error({
          message: 'Houve um erro durante a ação',
        });
        return;
      }
      notification.error({
        message: reason,
      });
    };

    return () => {
      window.onunhandledrejection = null;
    };
  }, []);

  return (
    <Routes>
      <Route path={'/'} element={<HomeView />} />
      <Route
        path={'/users/register'}
        element={<UserCreateView />}
      />
      <Route
        path={'/users/:id'}
        element={<UserDetailsView />}
      />
      <Route
        path={'/users/edit/:id'}
        element={<UserEditView />}
      />
      <Route path={'/users'} element={<UserListView />} />
      <Route path={'/bids'} element={<BuyOffersListView />} />
      <Route
        path={'/bids/register'}
        element={<BidCreateView />}
      />
      <Route
        path={'/bids/edit/:id'}
        element={<BidEditView />}
      />
      <Route
        path={'/bid-seller/register'}
        element={<PaymentSell />}
      />
      <Route
        path={'/quotes/register'}
        element={<CreateMarketData />}
      />
      <Route path={'/quotes'} element={<ListMarketData />} />
      <Route
        path={'/negotiations'}
        element={<NegotiationsList />}
      />
      <Route
        path={'/negotiations/register'}
        element={<NegotiationsCreate />}
      />
      <Route
        path={'/contract'}
        element={<ContractCreateForm />}
      />
      <Route
        path={'/contract/sign/:id'}
        element={<ConcractSignForm />}
      />
      <Route
        path={'/operations'}
        element={<OperationList />}
      />
      <Route
        path={'/operations/:id'}
        element={<OperationView />}
      />
      <Route
        path={'/contract/upload'}
        element={<ConcractUploadForm />}
      />
      <Route
        path={'/counter-bid/register'}
        element={<CounterBidCreateForm />}
      />
      <Route
        path={'/counter-bid'}
        element={<CounterBidList />}
      />
      <Route path={'/barter'} element={<BarterList />} />
      <Route
        path={'/barter/:id'}
        element={<BarterQuotationDetails />}
      />
      <Route path={'/login'} element={<Login />} />
      <Route
        path={'/representative'}
        element={<RepresentativeList />}
      />
      <Route
        path={'/representative/register'}
        element={<RepresentativeForm />}
      />
      <Route
        path={'/company'}
        element={<PreRegisterList />}
      />
      <Route path={'/leads-crm'} element={<LeadsCRM />} />
      <Route
        path={'/insurances-crm'}
        element={<InsurancesCRM />}
      />
    </Routes>
  );
}
