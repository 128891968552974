/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Select } from 'antd';
import { useState } from 'react';
import { Insurance } from 'uai-graos-sdk';
import { useInsurance } from '../../../core/hooks/useInsurance';
import TextArea from 'antd/es/input/TextArea';

export default function InsuranceKanBanDialog(
  props: Insurance.Insurance
): JSX.Element {
  const { updateInsurance } = useInsurance();

  const [insuranceForm, setInsuranceForm] = useState({
    ...props,
  });

  const statusList = [
    { name: 'Novo Seguro', value: 'NEW_INSURANCE' },
    { name: 'Contato Feito', value: 'CONTACT_MADE' },
    { name: 'Proposta Enviada', value: 'PROPOSAL_SENT' },
    {
      name: 'Proposta Cancelada',
      value: 'PROPOSAL_DECLINED',
    },
    { name: 'Refazer Proposta', value: 'REDO_PROPOSAL' },
    {
      name: 'Seguro Concretizado',
      value: 'INSURANCE_DONE',
    },
  ];

  const booleanList = [
    { name: 'Sim', value: true },
    { name: 'Não', value: false },
  ];

  const maritalStatusList = [
    { name: 'Casado', value: 'SINGLE' },
    { name: 'Solteiro', value: 'MARRIED' },
    { name: 'Divorciado', value: 'DIVORCED' },
    { name: 'Viúvo', value: 'WIDOWED' },
  ];

  const categoryPolicyList = [
    { name: 'Grupo', value: 'GROUP' },
    { name: 'Individual', value: 'INDIVIDUAL' },
  ];

  const usageTypeList = [
    { name: 'Profissional', value: 'PROFESSIONAL' },
    { name: 'Passeio', value: 'TOUR' },
    {
      name: 'Transporte de carga',
      value: 'CARGO_TRANSPORTATION',
    },
    { name: 'Aplicativo', value: 'APPLICATION' },
  ];

  const truckPartCoveredList = [
    { name: 'Conjunto Completo', value: 'COMPLETE_SET' },
    { name: 'Cavalo', value: 'HORSE' },
    { name: 'Carrinho', value: 'CART' },
  ];

  const transportRegionList = [
    { name: 'Regional', value: 'STATES' },
    { name: 'Nacional', value: 'NATIONAL' },
  ];

  const bodyworkTypeList = [
    { name: 'Aberta', value: 'OPEN' },
    { name: 'Baú', value: 'TRUCK' },
    { name: 'Frigorífico', value: 'REFRIGERATOR' },
    { name: 'Graneleiro', value: 'BULK' },
    { name: 'Outros', value: 'OTHERS' },
  ];

  const cargoTypeList = [
    { name: 'Animal', value: 'ANIMAL' },
    { name: 'Seca', value: 'DRY' },
    { name: 'Inflamavél', value: 'FLAMMABLE' },
    { name: 'Outras', value: 'MISCELLANEOUS' },
  ];

  const machineryTypeList = [
    { name: 'Trator', value: 'TRACTOR' },
    { name: 'Plantadeira', value: 'PLANTER' },
    { name: 'Colheitadeira', value: 'HARVESTER' },
  ];

  const getInsuranceName = (name: string) => {
    switch (name) {
      case 'VEHICLE':
        return 'Seguro de veículo';
      case 'MACHINERY':
        return 'Seguro de maquinário';
      case 'BUSINESS_RESIDENTIAL':
        return 'Seguro Residencial/Empresarial';
      case 'INDIVIDUAL_GROUP':
        return 'Seguro Individual/Grupo';
      case 'HARVEST':
        return 'Seguro de Safra';
      default:
        return 'Não informado';
    }
  };

  return (
    <div className='card-template'>
      <div className='e-card-content'>
        <table className='card-template-wrap'>
          <tbody>
            <tr>
              <td className='CardHeader'>Criado por</td>
              <Input
                disabled
                onChange={(e) =>
                  setInsuranceForm({
                    ...insuranceForm,
                    account: {
                      ...insuranceForm.account,
                      username: e.target.value,
                    },
                  })
                }
                value={insuranceForm.account?.username}
              />
            </tr>
            <tr>
              <td className='CardHeader'>
                Tipo de seguro:
              </td>
              <td>
                {getInsuranceName(
                  insuranceForm.insuranceCategory!
                )}
              </td>
            </tr>
            <tr>
              <td className='CardHeader'>Status:</td>
              <Select
                value={insuranceForm.insuranceStatus}
                onChange={(e) =>
                  setInsuranceForm({
                    ...insuranceForm,
                    insuranceStatus: e,
                  })
                }
                showSearch
                style={{ width: '100%' }}
                getPopupContainer={(trigger) =>
                  trigger.parentNode
                }
              >
                {statusList.map((status: any) => (
                  <Select.Option
                    key={status.value}
                    value={status.value}
                  >
                    {status.name}
                  </Select.Option>
                ))}
              </Select>
            </tr>
            <tr>
              <td className='CardHeader'>Telefone</td>
              <Input
                disabled
                onChange={(e) =>
                  setInsuranceForm({
                    ...insuranceForm,
                    account: {
                      ...insuranceForm.account,
                      phoneNumber: e.target.value,
                    },
                  })
                }
                value={insuranceForm.account?.phoneNumber}
              />
            </tr>
            <tr>
              <td className='CardHeader'>Cidade:</td>
              <td>
                {insuranceForm.account?.city} -{' '}
                {insuranceForm.account?.state}
              </td>
            </tr>
            <tr>
              <td className='CardHeader'>Novo seguro</td>
              <Select
                value={insuranceForm.newInsurance}
                onChange={(e) =>
                  setInsuranceForm({
                    ...insuranceForm,
                    newInsurance: e,
                  })
                }
                showSearch
                style={{ width: '100%' }}
                getPopupContainer={(trigger) =>
                  trigger.parentNode
                }
              >
                {booleanList.map((status: any) => (
                  <Select.Option
                    key={status.value}
                    value={status.value}
                  >
                    {status.name}
                  </Select.Option>
                ))}
              </Select>
            </tr>
            <tr>
              <td className='CardHeader'>Cep</td>
              <Input
                onChange={(e) =>
                  setInsuranceForm({
                    ...insuranceForm,
                    zipCode: e.target.value,
                  })
                }
                value={insuranceForm.zipCode}
              />
            </tr>

            {insuranceForm.maritalStatus !== undefined &&
              insuranceForm.maritalStatus !== null && (
                <tr>
                  <td className='CardHeader'>
                    Estado Civil:
                  </td>
                  <Select
                    value={insuranceForm.maritalStatus}
                    onChange={(e) =>
                      setInsuranceForm({
                        ...insuranceForm,
                        maritalStatus: e,
                      })
                    }
                    showSearch
                    style={{ width: '100%' }}
                    getPopupContainer={(trigger) =>
                      trigger.parentNode
                    }
                  >
                    {maritalStatusList.map(
                      (status: any) => (
                        <Select.Option
                          key={status.value}
                          value={status.value}
                        >
                          {status.name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </tr>
              )}

            {insuranceForm.cpfCnpj !== undefined &&
              insuranceForm.cpfCnpj !== null && (
                <tr>
                  <td className='CardHeader'>Cpf/Cnpj</td>
                  <Input
                    onChange={(e) =>
                      setInsuranceForm({
                        ...insuranceForm,
                        cpfCnpj: e.target.value,
                      })
                    }
                    value={insuranceForm.cpfCnpj}
                  />
                </tr>
              )}

            {insuranceForm.insuranceCategory ===
              'VEHICLE' && (
              <>
                {insuranceForm.sinister !== undefined &&
                  insuranceForm.sinister !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Houve algum sinistro?
                      </td>
                      <Select
                        value={insuranceForm.sinister}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            sinister: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.amountSinister !==
                  undefined &&
                  insuranceForm.amountSinister !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Quantidade de Sinistros
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            amountSinister: Number(
                              e.target.value
                            ),
                          })
                        }
                        value={insuranceForm.amountSinister}
                      />
                    </tr>
                  )}

                {insuranceForm.newVehicle !== undefined &&
                  insuranceForm.newVehicle !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Veículo novo?
                      </td>
                      <Select
                        value={insuranceForm.newVehicle}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            newVehicle: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.licensePlate !== undefined &&
                  insuranceForm.licensePlate !== null && (
                    <tr>
                      <td className='CardHeader'>Placa:</td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            licensePlate: e.target.value,
                          })
                        }
                        value={insuranceForm.licensePlate}
                      />
                    </tr>
                  )}

                {insuranceForm.vehicleType !== undefined &&
                  insuranceForm.vehicleType !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Tipo de veículo:
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            vehicleType: e.target.value,
                          })
                        }
                        value={insuranceForm.vehicleType}
                      />
                    </tr>
                  )}

                {insuranceForm.vehicleBrand !== undefined &&
                  insuranceForm.vehicleBrand !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Marca do veículo:
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            vehicleBrand: e.target.value,
                          })
                        }
                        value={insuranceForm.vehicleBrand}
                      />
                    </tr>
                  )}

                {insuranceForm.vehicleModel !== undefined &&
                  insuranceForm.vehicleModel !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Modelo do veículo:
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            vehicleModel: e.target.value,
                          })
                        }
                        value={insuranceForm.vehicleModel}
                      />
                    </tr>
                  )}

                {insuranceForm.mainDriver !== undefined &&
                  insuranceForm.mainDriver !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Condutor principal:
                      </td>
                      <Select
                        value={insuranceForm.mainDriver}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            mainDriver: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.vehicleYear !== undefined &&
                  insuranceForm.vehicleYear !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Ano do veículo:
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            vehicleYear: e.target.value,
                          })
                        }
                        value={insuranceForm.vehicleYear}
                      />
                    </tr>
                  )}

                {insuranceForm.categoryPolicy !==
                  undefined &&
                  insuranceForm.categoryPolicy !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Categoria de uso:
                      </td>
                      <Select
                        value={insuranceForm.categoryPolicy}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            categoryPolicy: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {categoryPolicyList.map(
                          (status: any) => (
                            <Select.Option
                              key={status.value}
                              value={status.value}
                            >
                              {status.name}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.usageType !== undefined &&
                  insuranceForm.usageType !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Tipo de uso:
                      </td>
                      <Select
                        value={insuranceForm.usageType}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            usageType: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {usageTypeList.map(
                          (status: any) => (
                            <Select.Option
                              key={status.value}
                              value={status.value}
                            >
                              {status.name}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.garageAtHome !== undefined &&
                  insuranceForm.garageAtHome !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Garagem na residência?
                      </td>
                      <Select
                        value={insuranceForm.garageAtHome}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            garageAtHome: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.garageAtWork !== undefined &&
                  insuranceForm.garageAtWork !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Garagem no trabalho?
                      </td>
                      <Select
                        value={insuranceForm.garageAtWork}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            garageAtWork: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.garageAtSchool !==
                  undefined &&
                  insuranceForm.garageAtSchool !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Garagem em local de estudos?
                      </td>
                      <Select
                        value={insuranceForm.garageAtSchool}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            garageAtSchool: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.truckPartCovered !==
                  undefined &&
                  insuranceForm.truckPartCovered !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Cavalo ou conjunto completo:
                      </td>
                      <Select
                        value={
                          insuranceForm.truckPartCovered
                        }
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            truckPartCovered: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {truckPartCoveredList.map(
                          (status: any) => (
                            <Select.Option
                              key={status.value}
                              value={status.value}
                            >
                              {status.name}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.transportRegion !==
                  undefined &&
                  insuranceForm.transportRegion !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Cobertura de transporte
                      </td>
                      <Select
                        value={
                          insuranceForm.transportRegion
                        }
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            transportRegion: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {transportRegionList.map(
                          (status: any) => (
                            <Select.Option
                              key={status.value}
                              value={status.value}
                            >
                              {status.name}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.transportStates !==
                  undefined &&
                  insuranceForm.transportStates !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Estados de transporte
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            transportStates: e.target.value,
                          })
                        }
                        value={
                          insuranceForm.transportStates
                        }
                      />
                    </tr>
                  )}

                {insuranceForm.cargoType !== undefined &&
                  insuranceForm.cargoType !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Tipo de carga
                      </td>
                      <Select
                        value={insuranceForm.cargoType}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            cargoType: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {cargoTypeList.map(
                          (status: any) => (
                            <Select.Option
                              key={status.value}
                              value={status.value}
                            >
                              {status.name}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.bodyworkType !== undefined &&
                  insuranceForm.bodyworkType !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Tipo de veículo
                      </td>
                      <Select
                        value={insuranceForm.bodyworkType}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            bodyworkType: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {bodyworkTypeList.map(
                          (status: any) => (
                            <Select.Option
                              key={status.value}
                              value={status.value}
                            >
                              {status.name}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </tr>
                  )}
              </>
            )}

            {insuranceForm.insuranceCategory ===
              'MACHINERY' && (
              <>
                {insuranceForm.machineryManufacturer !==
                  undefined &&
                  insuranceForm.machineryManufacturer !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Fabricante do maquinário
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            machineryManufacturer:
                              e.target.value,
                          })
                        }
                        value={
                          insuranceForm.machineryManufacturer
                        }
                      />
                    </tr>
                  )}

                {insuranceForm.machineryType !==
                  undefined &&
                  insuranceForm.machineryType !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Tipo de maquinário
                      </td>
                      <Select
                        value={insuranceForm.machineryType}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            machineryType: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {machineryTypeList.map(
                          (status: any) => (
                            <Select.Option
                              key={status.value}
                              value={status.value}
                            >
                              {status.name}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.machineryYear !==
                  undefined &&
                  insuranceForm.machineryYear !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Ano do maquinário
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            machineryYear: Number(
                              e.target.value.replace(
                                ',',
                                '.'
                              )
                            ),
                          })
                        }
                        value={insuranceForm.machineryYear}
                      />
                    </tr>
                  )}

                {insuranceForm.machineryMarketValue !==
                  undefined &&
                  insuranceForm.machineryMarketValue !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Valor do maquinário (R$)
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            machineryMarketValue: Number(
                              e.target.value.replace(
                                ',',
                                '.'
                              )
                            ),
                          })
                        }
                        value={
                          insuranceForm.machineryMarketValue
                        }
                      />
                    </tr>
                  )}

                {insuranceForm.fireCoverage !== undefined &&
                  insuranceForm.fireCoverage !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Proteção contra incendios?
                      </td>
                      <Select
                        value={insuranceForm.fireCoverage}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            fireCoverage: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.electricalCoverage !==
                  undefined &&
                  insuranceForm.electricalCoverage !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Proteção contra danos elétricos?
                      </td>
                      <Select
                        value={
                          insuranceForm.electricalCoverage
                        }
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            electricalCoverage: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.theftCoverage !==
                  undefined &&
                  insuranceForm.theftCoverage !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Proteção contra furtos/roubos?
                      </td>
                      <Select
                        value={insuranceForm.theftCoverage}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            theftCoverage: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.thirdPartyDamageCoverage !==
                  undefined &&
                  insuranceForm.thirdPartyDamageCoverage !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Proteção contra terceiros?
                      </td>
                      <Select
                        value={
                          insuranceForm.thirdPartyDamageCoverage
                        }
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            thirdPartyDamageCoverage: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}
              </>
            )}

            {insuranceForm.insuranceCategory ===
              'BUSINESS_RESIDENTIAL' && (
              <>
                {insuranceForm.businessActivity !==
                  undefined &&
                  insuranceForm.businessActivity !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Atividade da empresa
                      </td>
                      <Select
                        value={
                          insuranceForm.businessActivity
                        }
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            businessActivity: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.fireCoverage !== undefined &&
                  insuranceForm.fireCoverage !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Proteção contra incendios?
                      </td>
                      <Select
                        value={insuranceForm.fireCoverage}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            fireCoverage: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.electricalCoverage !==
                  undefined &&
                  insuranceForm.electricalCoverage !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Proteção contra danos elétricos?
                      </td>
                      <Select
                        value={
                          insuranceForm.electricalCoverage
                        }
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            electricalCoverage: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.theftCoverage !==
                  undefined &&
                  insuranceForm.theftCoverage !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Proteção contra furtos/roubos?
                      </td>
                      <Select
                        value={insuranceForm.theftCoverage}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            theftCoverage: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.thirdPartyDamageCoverage !==
                  undefined &&
                  insuranceForm.thirdPartyDamageCoverage !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Proteção contra terceiros?
                      </td>
                      <Select
                        value={
                          insuranceForm.thirdPartyDamageCoverage
                        }
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            thirdPartyDamageCoverage: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.windstormCoverage !==
                  undefined &&
                  insuranceForm.windstormCoverage !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Proteção contra vendavais?
                      </td>
                      <Select
                        value={
                          insuranceForm.windstormCoverage
                        }
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            windstormCoverage: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.allTimeAssistanceCoverage !==
                  undefined &&
                  insuranceForm.allTimeAssistanceCoverage !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Assistência 24h?
                      </td>
                      <Select
                        value={
                          insuranceForm.allTimeAssistanceCoverage
                        }
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            allTimeAssistanceCoverage: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.civilLiability !==
                  undefined &&
                  insuranceForm.civilLiability !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Cobrindo responsabilidade civil?
                      </td>
                      <Select
                        value={insuranceForm.civilLiability}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            civilLiability: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}
              </>
            )}

            {insuranceForm.insuranceCategory ===
              'INDIVIDUAL_GROUP' && (
              <>
                {insuranceForm.height !== undefined &&
                  insuranceForm.height !== null && (
                    <tr>
                      <td className='CardHeader'>Altura</td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            height: Number(
                              e.target.value.replace(
                                ',',
                                '.'
                              )
                            ),
                          })
                        }
                        value={insuranceForm.height}
                      />
                    </tr>
                  )}

                {insuranceForm.weight !== undefined &&
                  insuranceForm.weight !== null && (
                    <tr>
                      <td className='CardHeader'>Peso</td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            weight: Number(
                              e.target.value.replace(
                                ',',
                                '.'
                              )
                            ),
                          })
                        }
                        value={insuranceForm.weight}
                      />
                    </tr>
                  )}

                {insuranceForm.smoker !== undefined &&
                  insuranceForm.smoker !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Fumante
                      </td>
                      <Select
                        value={insuranceForm.smoker}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            smoker: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.extremeSports !==
                  undefined &&
                  insuranceForm.extremeSports !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Pratica algum esporte radical?
                      </td>
                      <Select
                        value={insuranceForm.extremeSports}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            extremeSports: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.businessActivity !==
                  undefined &&
                  insuranceForm.businessActivity !==
                    null && (
                    <tr>
                      <td className='CardHeader'>
                        Atividade da empresa
                      </td>
                      <Select
                        value={
                          insuranceForm.businessActivity
                        }
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            businessActivity: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}

                {insuranceForm.numberOfLives !==
                  undefined &&
                  insuranceForm.numberOfLives !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Número de vidas
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            numberOfLives: Number(
                              e.target.value
                            ),
                          })
                        }
                        value={insuranceForm.numberOfLives}
                      />
                    </tr>
                  )}
              </>
            )}

            {insuranceForm.insuranceCategory ===
              'HARVEST' && (
              <>
                {insuranceForm.harverstLng !== undefined &&
                  insuranceForm.harverstLng !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Longitude:
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            harverstLng: Number(
                              e.target.value
                            ),
                          })
                        }
                        value={insuranceForm.harverstLng}
                      />
                    </tr>
                  )}

                {insuranceForm.harverstLat !== undefined &&
                  insuranceForm.harverstLat !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Latitude:
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            harverstLat: Number(
                              e.target.value
                            ),
                          })
                        }
                        value={insuranceForm.harverstLat}
                      />
                    </tr>
                  )}

                {insuranceForm.plantingDate !== undefined &&
                  insuranceForm.plantingDate !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Data de plantio:
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            plantingDate: e.target.value,
                          })
                        }
                        value={insuranceForm.plantingDate}
                      />
                    </tr>
                  )}

                {insuranceForm.financedAmount !==
                  undefined &&
                  insuranceForm.financedAmount !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Valor Financiado (R$):
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            financedAmount: Number(
                              e.target.value
                            ),
                          })
                        }
                        value={insuranceForm.financedAmount}
                      />
                    </tr>
                  )}

                {insuranceForm.valueBags !== undefined &&
                  insuranceForm.valueBags !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Valor da saca (R$):
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            valueBags: Number(
                              e.target.value
                            ),
                          })
                        }
                        value={insuranceForm.valueBags}
                      />
                    </tr>
                  )}

                {insuranceForm.riskArea !== undefined &&
                  insuranceForm.riskArea !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Area de risco:
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            riskArea: Number(
                              e.target.value
                            ),
                          })
                        }
                        value={insuranceForm.riskArea}
                      />
                    </tr>
                  )}
                {insuranceForm.soilType !== undefined &&
                  insuranceForm.soilType !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Tipo de solo:
                      </td>
                      <Input
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            soilType: e.target.value,
                          })
                        }
                        value={insuranceForm.soilType}
                      />
                    </tr>
                  )}

                {insuranceForm.sketchArea !== undefined &&
                  insuranceForm.sketchArea !== null && (
                    <tr>
                      <td className='CardHeader'>
                        Possui planta da área?
                      </td>
                      <Select
                        value={insuranceForm.sketchArea}
                        onChange={(e) =>
                          setInsuranceForm({
                            ...insuranceForm,
                            sketchArea: e,
                          })
                        }
                        showSearch
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) =>
                          trigger.parentNode
                        }
                      >
                        {booleanList.map((status: any) => (
                          <Select.Option
                            key={status.value}
                            value={status.value}
                          >
                            {status.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </tr>
                  )}
              </>
            )}
            <tr>
              <td className='CardHeader'>Observações:</td>
              <TextArea
                onChange={(e) =>
                  setInsuranceForm({
                    ...insuranceForm,
                    observations: e.target.value,
                  })
                }
                value={insuranceForm.observations}
                showCount
                maxLength={350}
                style={{ height: 120, marginBottom: 24 }}
                placeholder='Insira uma observação'
              />
            </tr>
            <tr>
              <td className='CardHeader'>
                <Button
                  type='primary'
                  onClick={() =>
                    updateInsurance(props.id!, {
                      ...insuranceForm,
                      driverInsurances: undefined,
                    })
                  }
                >
                  Salvar
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
