/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../services/Api';
import { Descriptions, Skeleton, Tabs, Tag } from 'antd';

export default function OperationView() {
  const [operation, setOperation] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const params = useParams<{ id: string | undefined }>();
  const [activeTab, setActiveTab] = useState<
    'buyer' | 'seller' | 'broker' | 'tax' | 'operation'
  >('operation');

  useEffect(() => {
    getOperation();
  }, []);

  async function getOperation() {
    await Api.getOperationById(params.id).then((resp) => {
      setOperation(resp.data);
    });
    setLoading(false);
  }

  if (loading) {
    return <Skeleton />;
  }

  return (
    <>
      <Tabs
        defaultActiveKey={'buyer'}
        activeKey={activeTab}
        onChange={(tab) =>
          setActiveTab(
            tab as 'buyer' | 'seller' | 'broker' | 'tax'
          )
        }
      >
        <Tabs.TabPane
          key={'operation'}
          tab={'Dados da Operação'}
        >
          <Descriptions
            title='Dados da Operação'
            bordered
            column={1}
          >
            <Descriptions.Item label='ID da Operação'>
              {operation?.id}
            </Descriptions.Item>
            <Descriptions.Item label='Data de Criação'>
              {operation?.createdAt}
            </Descriptions.Item>
            <Descriptions.Item label='Produto'>
              {operation?.product}
            </Descriptions.Item>
            <Descriptions.Item label='Quantidade'>
              {operation?.quantity}
            </Descriptions.Item>
            <Descriptions.Item label='Status da Operação'>
              {operation?.status === 'DELAYED' ? (
                <Tag color='magenta'>ATRASADO</Tag>
              ) : operation?.status === 'CANCELLED' ? (
                <Tag color='red'>CANCELADO</Tag>
              ) : operation?.status === 'REFUSED' ? (
                <Tag color='volcano'>RECUSADO</Tag>
              ) : operation?.status === 'SOLD' ? (
                <Tag color='green'>VENDIDO</Tag>
              ) : operation?.status === 'PAID' ? (
                <Tag color='cyan'>PAGO</Tag>
              ) : (
                <Tag color='purple'>EM ANÁLISE</Tag>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Tabs.TabPane>
        <Tabs.TabPane
          key={'buyer'}
          tab={'Dados do Comprador'}
        >
          <Descriptions
            title='Dados do Comprador'
            bordered
            column={1}
          >
            <Descriptions.Item label='ID do Comprador'>
              {operation?.operationBuyer.buyerId}
            </Descriptions.Item>
            <Descriptions.Item label='Nome'>
              {operation?.operationBuyer.buyerName}
            </Descriptions.Item>
            <Descriptions.Item label='CEP'>
              {operation?.operationBuyer.buyerZipCode}
            </Descriptions.Item>
            <Descriptions.Item label='Endereço'>
              {operation?.operationBuyer.buyerAddress}
            </Descriptions.Item>
            <Descriptions.Item label='Número'>
              {operation?.operationBuyer.buyerAddressNumber}
            </Descriptions.Item>
            <Descriptions.Item label='Bairro'>
              {operation?.operationBuyer.buyerNeighborhood}
            </Descriptions.Item>
            <Descriptions.Item label='Estado'>
              {operation?.operationBuyer.buyerState}
            </Descriptions.Item>
            <Descriptions.Item label='Complemento'>
              {
                operation?.operationBuyer
                  .buyerAddressComplement
              }
            </Descriptions.Item>
            <Descriptions.Item label='CPF/CNPJ'>
              {operation?.operationBuyer.buyerDocument}
            </Descriptions.Item>
          </Descriptions>
        </Tabs.TabPane>

        <Tabs.TabPane
          key={'seller'}
          tab={'Dados do Vendedor'}
        >
          <Descriptions
            title='Dados do Vendedor'
            bordered
            column={1}
          >
            <Descriptions.Item label='ID do Vendedor'>
              {operation?.operationSeller.sellerId}
            </Descriptions.Item>
            <Descriptions.Item label='Nome'>
              {operation?.operationSeller.sellerName}
            </Descriptions.Item>
            <Descriptions.Item label='CEP'>
              {operation?.operationSeller.sellerZipCode}
            </Descriptions.Item>
            <Descriptions.Item label='Endereço'>
              {operation?.operationSeller.sellerAddress}
            </Descriptions.Item>
            <Descriptions.Item label='Número'>
              {
                operation?.operationSeller
                  .sellerAddressNumber
              }
            </Descriptions.Item>
            <Descriptions.Item label='Bairro'>
              {
                operation?.operationSeller
                  .sellerNeighborhood
              }
            </Descriptions.Item>
            <Descriptions.Item label='Estado'>
              {operation?.operationSeller.sellerState}
            </Descriptions.Item>
            <Descriptions.Item label='Complemento'>
              {
                operation?.operationSeller
                  .sellerAddressComplement
              }
            </Descriptions.Item>
            <Descriptions.Item label='CPF/CNPJ'>
              {operation?.operationSeller.sellerDocument}
            </Descriptions.Item>
          </Descriptions>
        </Tabs.TabPane>

        <Tabs.TabPane key={'tax'} tab={'Dados de Imposto'}>
          <Descriptions
            title='Dados de Imposto'
            bordered
            column={1}
          >
            <Descriptions.Item label='Valor de Imposto'>
              R$ {operation?.operationTax.tax}
            </Descriptions.Item>
            <Descriptions.Item label='Imposto a pagar'>
              R$ {operation?.operationTax.taxPayable}
            </Descriptions.Item>
          </Descriptions>
        </Tabs.TabPane>
        <Tabs.TabPane
          key={'broker'}
          tab={'Dados da Corretora'}
        >
          <Descriptions
            title='Dados da Corretora'
            bordered
            column={1}
          >
            <Descriptions.Item label='Valor Bruto'>
              R${' '}
              {operation?.operationBroker.grossPaymentValue}
            </Descriptions.Item>
            <Descriptions.Item label='Valor Líquido'>
              R${' '}
              {operation?.operationBroker.netPaymentValue}
            </Descriptions.Item>
          </Descriptions>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
