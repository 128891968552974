import { Form, Input, Button, notification } from 'antd';
import {
  LockOutlined,
  MailOutlined,
} from '@ant-design/icons';
import Logo from '../../../assets/LogoUaiGraos.png';
import * as S from './Login.styles';
import AuthService from '../../../auth/Authorization.service';

const Login = () => {
  const onFinish = async (values: any) => {
    const config = {
      login: values.email,
      password: values.password,
    };
    AuthService.getFirstAccessTokens(config).catch(
      (err: any) => {
        notification.error({
          message: err,
        });
      }
    );
  };

  return (
    <S.LoginContainer>
      <S.LoginArea
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '800px',
        }}
      >
        <S.LogoContainer src={Logo} alt='Logo' />
        <S.FormContainer>
          <Form
            name='normal_login'
            className='login-form'
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input
                prefix={
                  <MailOutlined className='site-form-item-icon' />
                }
                placeholder='E-mail'
              />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input.Password
                prefix={
                  <LockOutlined className='site-form-item-icon' />
                }
                type='password'
                placeholder='Password'
              />
            </Form.Item>

            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='login-form-button'
              >
                Login
              </Button>
              <Button style={{ marginTop: 10 }} type='link'>
                Esqueci minha senha
              </Button>
            </Form.Item>
          </Form>
        </S.FormContainer>
      </S.LoginArea>
    </S.LoginContainer>
  );
};

export default Login;
