import moment from 'moment';
import { Insurance } from 'uai-graos-sdk';
import { formatPhoneTable } from '../../../core/utils/formatPhoneNumber';
import * as S from './styles';
import { ExclamationCircleFilled } from '@ant-design/icons';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { Modal } from 'antd';

export default function InsuranceKanBanCard(props: Insurance.Insurance) {
  const { confirm } = Modal;

  function callWhatsApp() {
    confirm({
      title: 'Atenção!',
      icon: <ExclamationCircleFilled />,
      content: `Deseja entrar em contato com ${props.account?.username} via WhatsApp? ${formatPhoneTable(props.account?.phoneNumber!)}`,
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => window.open(`https://wa.me/${props.account?.phoneNumber}`, '_blank')
    });
  }

  const getInsuranceName = (name: string) => {
    switch (name) {
      case 'VEHICLE':
        return 'Veículo';
      case 'MACHINERY':
        return 'Maquinário';
      case 'BUSINESS_RESIDENTIAL':
        return 'Residencial/Empresarial';
      case 'INDIVIDUAL_GROUP':
        return 'Individual/Grupo';
      case 'HARVEST':
        return 'Safra';
      default:
        return 'Não informado';
    }
  };


  return (
    <S.Container>
      <S.Name>{props.account?.username}</S.Name>
      <S.LocationArea>
        <LocationCityIcon sx={{ marginRight: 1 }} />
        {props.account?.city} - {props.account?.state}
      </S.LocationArea>
      <div>
        Criado em:{' '}
        <b>
        {moment(props.createdAt).format('DD/MM/YYYY')}
        </b>
      </div>
      <div>
        Tipo de seguro: 
        <b>
        {getInsuranceName(props.insuranceCategory!)}
        </b>
      </div>
      <S.PhoneButtonArea>
        <S.WhatsAppButton onClick={() => callWhatsApp()}>
          <LocalPhoneIcon sx={{ marginRight: 1 }} />
          {formatPhoneTable(props?.account?.phoneNumber!)}
        </S.WhatsAppButton>
      </S.PhoneButtonArea>
    </S.Container>
  );
}